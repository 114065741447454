import React from "react"
import { Helmet } from "react-helmet"

import Nav from "../partials/Nav"
import PageSection from "../layout/PageSection"
import NominateBanner from "../partials/NominateBanner"
import YouHero from "../partials/YouHero"
import Footer from "../partials/Footer"
import CookieBanner from "../partials/CookieBanner"

import "../styles.css"

const AboutUsPage: React.FC<{}> = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Thank you | Run For Heroes</title>
        <meta name="description" content="Thank you for donating." />
        <html lang="en" />
      </Helmet>
      <Nav />
      <PageSection backgroundColor={"BLUE"}>
        <YouHero />
      </PageSection>
      <PageSection backgroundColor={"WHITE"}>
        <NominateBanner />
      </PageSection>
      <PageSection backgroundColor={"GREY"}>
        <Footer />
      </PageSection>
      <CookieBanner />
    </>
  )
}

export default AboutUsPage
