import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import BodyText from "../components/BodyText"
import { LAYOUT, FONT_FAMILIES, COLORS, LINKS } from "../constants"

const Root = styled.a`
  position: relative;
  padding: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: center;
  text-decoration: none;
`

interface IImg {
  fixed: object
}

const ShareImg = styled(Img)<IImg>`
  width: 100%;
  min-width: 24px;
  margin-left: 14px;
`

const StyledBodyText = styled(BodyText)`
  p {
    color: ${COLORS.BLUE};
  }
`

interface INominateBanner {
  rest?: object
}

const NominateBanner: React.FC<INominateBanner> = ({ ...rest }) => {
  const data = useStaticQuery(graphql`
    query NominateBannerQuery {
      share: file(relativePath: { eq: "nominateBanner/share.png" }) {
        childImageSharp {
          fixed(width: 30) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Root href={LINKS.INSTAGRAM} rel={"noopener"} target={"_blank"} {...rest}>
      <StyledBodyText type={"DARK"} alignment={"CENTER"}>
        <p>Don’t forget to nominate your friends to get involved</p>
      </StyledBodyText>

      <ShareImg
        loading={"eager"}
        fadeIn={false}
        fixed={data.share.childImageSharp.fixed}
        alt={"£7 million"}
      />
    </Root>
  )
}

export default NominateBanner
