import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import BodyText from "../components/BodyText"
import { LAYOUT, FONT_FAMILIES, LINKS, COLORS } from "../constants"

const Root = styled.div`
  position: relative;
`

const HeroContainer = styled.div`
  max-width: 906px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 20px;
  padding-top: 40px;

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    padding-top: 100px;
    flex-direction: unset;
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;
  }
`

const SummaryDescription = styled(BodyText)`
  max-width: 300px;
  padding: 0 20px;
  margin: 0 auto 20px auto;
  p,
  a {
    font-size: 1.6rem;
  }

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    padding: 0;
    margin-bottom: 30px;
  }
`

const GoodieBagWrapper = styled.a`
  margin: 0 auto;
  text-decoration: none;
  display: flex;
  width: 100%;
  max-width: 455px;
  border-radius: 8px;
  border: solid 1px #ffffff;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s;
  &:hover {
    opacity: 0.8;
  }
`

interface IImg {
  fluid: object
}

const HeroImg = styled(Img)<IImg>`
  width: 100%;
  max-width: 298px;
  margin: 0 auto 40px auto;
`

const GoodieBagImg = styled(Img)<IImg>`
  width: 100%;
  max-width: 132px;
`

const StyledBodyText = styled(BodyText)`
  font-family: ${FONT_FAMILIES.RFH_REGULAR};

  br {
    @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
      display: none;
    }
  }
`

interface IYouHero {
  rest?: object
}

const YouHero: React.FC<IYouHero> = ({ ...rest }) => {
  const data = useStaticQuery(graphql`
    query YouHeroQuery {
      hero: file(relativePath: { eq: "youHero/hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 298) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      goodieBag: file(relativePath: { eq: "youHero/goodie-bag.png" }) {
        childImageSharp {
          fluid(maxWidth: 132) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  return (
    <Root {...rest}>
      <HeroContainer>
        <HeroImg
          loading={"eager"}
          fadeIn={false}
          fluid={data.hero.childImageSharp.fluid}
          alt={"You hero"}
        />
        <SummaryDescription type={"LIGHT"} alignment={"CENTER"}>
          <p>
            Thank you for participating in <br />
            <strong>Run For Heroes #5kMay</strong>
            <br /> Your donation means a lot!
          </p>
        </SummaryDescription>
        <GoodieBagWrapper
          href={LINKS.GOODIEBAG}
          target={"_blank"}
          rel={"noopener"}
        >
          <GoodieBagImg
            loading={"eager"}
            fadeIn={false}
            fluid={data.goodieBag.childImageSharp.fluid}
            alt={"Goodie bag"}
          />
          <StyledBodyText>
            <p>
              Download your
              <br /> goodie bag
            </p>
          </StyledBodyText>
        </GoodieBagWrapper>
      </HeroContainer>
    </Root>
  )
}

export default YouHero
